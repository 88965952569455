// @flow
import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { navConfig } from '../Navigation';
import Button from '../Button';
import { EmployeeInfo } from '../EmployeeCard';
import Footer from '../Footer';

import {
  MainImage,
  BgImage,
  PositionWrapper,
  HeaderWrapper,
  LightHeader,
  Intro,
  Paragraph,
  List,
  PositionFlexChild,
  ApplyWrapper,
  ContactWrapper,
  H1,
  H4,
  H6,
  Spacer,
} from './styles';

const Jobs = ({ data }: Object) => {
  // debugger;
  const { staffImgMain } = data;
  return (
    <div>
      <Helmet>
        <title>Mcon | Lediga tjänster</title>
      </Helmet>
      <MainImage fluid={staffImgMain.childImageSharp.fluid} alt="Jobbild" />
      <PositionWrapper>
        <PositionFlexChild>
          <HeaderWrapper>
            <H1>Redovisningskonsult</H1>
          </HeaderWrapper>
          <LightHeader>MCON | Göteborg, Sverige</LightHeader>
          <Intro>
            Vill du bli en del av ett riktigt drivet och vasst gäng
            redovisningskonsulter som alltid sätter kunden i fokus och ger det
            lilla extra?
          </Intro>
          <Intro secondary>
            MCON är byrån som sticker ut i sin bransch med sin kvalitativa och
            personliga service tillsammans med en nytänkande företagskultur. Vi
            är framtidens redovisningsbyrå och du vill inte missa chansen att
            bli en nyckelspelare i byråns fortsatta framgångssaga!
          </Intro>
        </PositionFlexChild>
        <PositionFlexChild>
          <H4>Om tjänsten</H4>
          <Paragraph>
            Som redovisningskonsult har du helhetsansvar för dina kunder. Det
            innebär att du är ansvarig för att ge förstklassig och professionell
            leverans av den ekonomiska hjälp som du och kunden har kommit
            överens om. MCons kunder verkar inom alla branscher. Arbetsuppgifter
            inkluderar löpande bokföring, rådgivning i redovisningsfrågor och
            principer samt assistans vid framtagning av ekonomiska analyser och
            rapporter. Vi använder oss av Fortnox för bokföring samt gör bokslut
            i Hogia. Det är inget krav på tidigare kunskaper i dessa system.
          </Paragraph>
          <H6>Dina kunskaper</H6>
          <List>
            <li>Relevant ekonomutbildning på högskole- eller YH-nivå</li>
            <li>3-5 års erfarenhet av löpande bokföring</li>
            <li>Självgående inom löpande bokföring</li>
            <li>Auktoriserad inom redovisning (meriterande)</li>
            <li>
              Erfarenhet av lättare lönearbete och/eller kan göra månads-och
              årsbokslut (meriterande)
            </li>
          </List>
        </PositionFlexChild>

        <BgImage imgUrl="staffimg1.png" />
        <BgImage imgUrl="staffimg2.png" />
        <BgImage imgUrl="staffimg3.png" />

        <PositionFlexChild>
          <Spacer>
            <H4>Om oss</H4>
            <Paragraph>
              MCON är en modern redovisningsbyrå som erbjuder små och medelstora
              företag tjänster inom bokföring, redovisning, lön och
              administration. I mindre företag kan vi ta hand om hela bolagets
              ekonomi och administration. Medelstora och större företag hjälper
              vi med specifika tjänster men också kompletterande, ibland
              vikarierande roller på konsultbasis.
            </Paragraph>
          </Spacer>

          <Spacer>
            <H4>Vad vi erbjuder</H4>
            <List>
              <li>
                Centralt belägen arbetsplats inom Vallgraven i fräsch
                vindsvåning
              </li>
              <li>
                Kontinuerlig fortbildning och stimulerande arbetsuppgifter
              </li>
              <li>Årlig konferensresa utomlands</li>
              <li>Trevliga kollegor och en härlig jobbkultur</li>
            </List>
          </Spacer>
        </PositionFlexChild>
        <PositionFlexChild>
          <Link to={navConfig.contact.url} id="test-id-apply-job-link">
            <ApplyWrapper>
              <Button type="primary" large>
                Ansök här
              </Button>
            </ApplyWrapper>
          </Link>
        </PositionFlexChild>
        <ContactWrapper>
          <LightHeader>Kontakt</LightHeader>
          <EmployeeInfo
            imgUrl=""
            name="Lars Fredholm"
            position="Kontorschef"
            email="lars@mconab.se"
            phone="073 059 45 58"
          />
        </ContactWrapper>
      </PositionWrapper>
      <Footer />
    </div>
  );
};

export default Jobs;

export const jobsQuery = graphql`
  query {
    staffImgMain: file(relativePath: { eq: "jobs/mainStaffImg.jpg" }) {
      ...fluidImage
    }
    staffImg1: file(relativePath: { eq: "jobs/staffimg1.png" }) {
      ...fluidImage
    }
    staffImg2: file(relativePath: { eq: "jobs/staffimg2.png" }) {
      ...fluidImage
    }
    staffImg3: file(relativePath: { eq: "jobs/staffimg3.png" }) {
      ...fluidImage
    }
  }
`;
