// @flow
import styled from 'styled-components';
import { withPrefix } from 'gatsby';

import { media } from '../../utils/style-utils';

export const ContactPage = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(to right, #2356ac, #2356ac 15%, #4791eb 150%);
  padding: 64px 0 24px 0;
  overflow: hidden;

  ${media.tablet`
    display: block;
    padding: 24px 0 24px 0;
  `};

  ${media.desktop`
    display: flex;
    justify-content: space-between;
    padding: 24px 0 24px 0;
  `};

  ${media.hd`
    display: flex;
    justify-content: center;
    padding: 24px 0 24px 0;
  `};
`;

export const LocationImgWrapper = styled.div`
  position: relative;
  width: 450px;
  height: 400px;
  margin: 0 0 24px 24px;

  ${media.tablet`
    width: 475px;
    height: 422px;
    margin: 36px -50px -180px auto;
  `};

  ${media.desktop`
    width: 700px;
    min-width: 700px;
    height: 622px;
    margin: 48px 0 24px 24px;
  `};

  ${media.hd`
    width: 800px;
    min-width: 800px;
    height: 711px;
    margin: 48px 0 24px auto;
  `};
`;

export const LocationImgBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${withPrefix(`./contact/location_bg_mobile.svg`)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${media.tablet`
    background: url(${withPrefix(`./contact/location_bg_tablet.svg`)});
    background-size: contain;
    background-repeat: no-repeat;
  `};

  ${media.desktop`
    background: url(${withPrefix(`./contact/location_bg.svg`)});
    background-size: contain;
    background-repeat: no-repeat;
  `};
`;

export const LocationImg = styled(LocationImgBg)`
  background: url(${withPrefix(`./contact/location_mobile.png`)});
  background-repeat: no-repeat;
  background-size: contain;
  width: 95%;
  height: 95%;
  margin: auto;
  z-index: 2;

  ${media.tablet`
    background: url(${withPrefix(`./contact/location_tablet.png`)}); 
    background-size: contain;
    background-repeat: no-repeat;
  `};

  ${media.desktop`
    background: url(${withPrefix(`./contact/location.png`)}); 
    background-size: contain;
    background-repeat: no-repeat;
  `};
`;

export const LocationWrapper = styled.div`
  position: absolute;
  left: auto;
  top: 120px;
  left: 75px;
  z-index: 3;

  & > a {
    text-decoration: none;
  }

  ${media.tablet`
    left: auto;
    top: 80px;
    right: 190px;
  `};

  ${media.desktop`
    left: 55px;
    top: 145px;
    right: auto;
  `};

  ${media.hd`
    top: 175px;
    left: 85px;
  `};
`;

export const Address = styled.div`
  color: #fff;
  text-align: left;
`;

export const Pin = styled.div`
  background: url(${withPrefix(`./contact/pin.png`)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 50px;
  margin-left: 16px;

  ${media.tablet`
    width: 46px;
    height: 56px;
  `};

  ${media.desktop`
      width: 60px;
      height: 78px;
      margin-left: 36px;
  `};
`;

export const AddressHeader = styled.h4`
  font-family: 'Playfair', serif;
  font-weight: 800;
  font-size: 24px;

  ${media.tablet`
    font-size: 32px;
  `};

  ${media.desktop`
    font-size: 36px;
  `};
`;

export const AddressBody = styled.p`
  font-weight: 300;
  font-size: 14px;

  ${media.tablet`
    font-size: 16px;
  `};

  ${media.desktop`
    font-size: 18px;
  `};
`;

export const FlexRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FlexWrapper = styled(FlexRowWrap)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${media.tablet`
    flex-wrap: no-wrap;
  `};
`;

export const Wrapper = styled.section`
  margin: 0 12px 32px;
  text-align: left;
  color: #fff;
  position: relative;
  z-index: 9;
  width: calc(100% - 24px);

  ${media.phone`
    margin: 0 auto 32px 32px;
    width: calc(100% - 64px);
  `};

  ${media.tablet`
    margin: 40px auto 0 36px;
    max-width: 400px;
  `};

  ${media.desktop`
    margin: 136px 32px 0 auto;
    max-width: 500px;
  `};

  ${media.hd`
    margin: 112px 10% 0 auto;
    max-width: 500px;
  `};
`;

export const InfoWrapper = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &:first-child {
    margin-bottom: 8px;
  }

  ${media.tablet`
    flex-basis: calc(50% - 6px);
  `};
`;
export const InfoHeader = styled.p`
  font-weight: 400;
  margin-right: 6px;
`;
export const InfoValue = styled.p`
  font-weight: 800;
`;
