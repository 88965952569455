// @flow
import styled from 'styled-components';
import { withPrefix } from 'gatsby';

import { media } from '../../utils/style-utils';

export const Article = styled.div`
  overflow-x: hidden;
`;

export const Section = styled.div`
  position: relative;
  height: 1050px;
  width: 100vw;
  background: ${({ background }) => background};

  &:first-child {
    height: 570px;
  }

  &:nth-child(2) {
    height: 900px;
  }

  ${media.phone`
    &:first-child {
      height: 670px;
    }

    &:nth-child(2) {
      height: 1050px;
    }

  `};

  ${media.tablet`
    &:first-child {
      height: 1024px;
    }
  `};
`;

export const FlexSection = styled(Section)`
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 100px 36px;
  overflow: hidden;

  ${media.tablet`
    justify-content: left;
    padding: 100px 64px;
  `};

  ${media.desktop`
    padding: 200px 120px;
  `};

  ${media.hd`
    padding: 200px 12.5%;
  `};
`;

export const FlexChild = styled.div`
  width: 100%;
  position: relative;
  height: 350px;

  ${media.desktop`
    flex: 1;
  `};
`;

export const SwapOrderFlexChild = styled(FlexChild)`
  order: -1;

  ${media.desktop`
    order: 2;
  `};
`;

export const FlexContent = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 10rem 0 4rem 0;

  ${media.tablet`
    padding: 7rem 0 4rem 0;
  `};

  ${media.desktop`
    flex: 1;
    padding: 3rem 0 4rem 0;
    margin-left: 3rem;
  `};

  ${media.hd`
    padding: 0 0 4rem 0;
    max-width: 500px;
    margin-left: 0;
  `};
`;

export const HeaderWrapper = styled.div`
  ${media.tablet`
    max-width: 350px;
  `};

  ${media.desktop`
    max-width: 420px;
  `};

  ${media.hd`
    max-width: 500px;
  `};
`;

export const ContentWrapper = styled.div`
  position: absolute;
  width: 475px;
  height: 422px;
  left: -50px;
  bottom: 0px;

  &#recruit {
    left: unset;
    right: -120px;
  }

  ${media.phone`
    bottom: -15px;
    right: -50px;

    &#recruit {
      width: 510px;
      height: 460px;
      right: -140px;
    }
  `};

  ${media.tablet`
    width: 575px;
    height: 511px;
    left: -20px;
    bottom: 70px;

    &#recruit {
      width: 600px;
      height: 555px;
      left: unset;
      right: -50px;
      bottom: -50px;
    }
  `};

  ${media.desktop`
    width: 700px;
    height: 622px;
    bottom: 50px;
    left: unset;
    right: 50px;

    &#recruit {
      width: 100%;
      height: 511px;
      right: -5%;
    }
  `};

  ${media.hd`
    width: 800px;
    height: 844px;
    left: unset;
    right: -5px;
    bottom: 0px;

    &#recruit {
      width: 700px;
      height: 622px;
      right: -100px;
      bottom: -100px;
    }
  `};
`;

export const ContentImgBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ url }) => `url(${withPrefix(`./about/${url}`)})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const ContentImg = styled(ContentImgBg)`
  background-repeat: no-repeat;
  background-size: contain;
  width: 95%;
  height: 95%;
  margin: auto;
  z-index: 2;
`;

export const Content = styled.div`
  position: absolute;
  top: 100px;
  left: 70px;
  z-index: 3;
  max-width: 90vw;

  ${media.phone`
    left: 8 0px;
    top: 90px;
    max-width: 330px;
  `};

  ${media.tablet`
    top: 120px;
    left: 85px;
    max-width: 422px;
  `};

  ${media.desktop`
    top: 180px;
    left: 120px;
    max-width: 510px;
  `};

  ${media.hd`
    top: 240px;
    left: 130px;
    max-width: 560px;
  `};
`;

export const RelativeParent = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
`;

export const EmployeeContainer = styled.div`
  padding: 80px 12px 0 36px;

  ${media.tablet`
    padding: 12px 0px 0px 56px;
  `};

  ${media.desktop`
    min-height: 700px;
  `};

  ${media.hd`
    padding: 24px 0px 0px 72px;
    min-height: 700px;
  `};
`;

export const ArrowButton = styled.div`
  background: url(${withPrefix('./Button/Arrow.svg')});
  background-position: center;
  background-size: contain;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 30%;
  left: ${props => (props.reverse ? '24px' : 'auto')};
  right: ${props => (props.reverse ? 'auto' : '24px')};
  transform: ${props => (props.reverse ? 'rotate(180deg)' : 'rotate(0deg)')};
  z-index: 9;
  cursor: pointer;
`;

export const EmployeeWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
  margin: 120px 0px;

  ${media.tablet`
    margin: 180px 0; 
  `};

  ${media.desktop`
    margin: 0;
    padding-top: 180px;
  `};
`;
