// @flow
export type Employee = {
  name: string,
  position: string,
  imgUrl: string,
  email: string,
  phone: string,
};
export type Employees = Array<Employee>;

export const PLACEHOLDERS = {
  WOMEN: 'placeholder_women',
  MEN: 'placeholder_men',
};

const employees: Employees = [
  {
    name: 'Maria Fredholm',
    position: 'Auktoriserad Redovisningskonsult, VD & Partner',
    imgUrl: 'maria_fredholm',
    email: 'maria@mconab.se',
    phone: '070 340 63 32',
  },
  {
    name: 'Sara Mankell',
    position: 'Auktoriserad Redovisningskonsult, Partner',
    imgUrl: 'sara_mankell',
    email: 'sara@mconab.se',
    phone: '073 345 68 27',
  },
  {
    name: 'Hanna Tomasson',
    position: 'Auktoriserad Redovisningskonsult, Partner',
    imgUrl: 'hanna_tomasson',
    email: 'hanna@mconab.se',
    phone: '070 258 34 98',
  },
  {
    name: 'Maria Björnberg',
    position: 'Auktoriserad Redovisningskonsult, Partner',
    imgUrl: 'maria_bjornberg',
    email: 'mariab@mconab.se',
    phone: '070 275 48 12',
  },
  {
    name: 'Frida Tjernström',
    position: 'Redovisningskonsult',
    imgUrl: 'frida_tjernstrom',
    email: 'frida@mconab.se',
    phone: '073 046 27 55',
  },
  {
    name: 'Emma Blom',
    position: 'Redovisningskonsult',
    imgUrl: 'emma_blom',
    email: 'emma@mconab.se',
    phone: '073 700 27 55',
  },
  {
    name: 'Nathalie Priba',
    position: 'Redovisningskonsult',
    imgUrl: 'nathalie_priba',
    email: 'nathalie@mconab.se',
    phone: '072 333 38 78',
  },
  {
    name: 'Ida Lomberg',
    position: 'Redovisningskonsult',
    imgUrl: 'ida_lomberg',
    email: 'ida@mconab.se',
    phone: '070 778 42 28',
  },
  {
    name: 'Mikayla Nguyen',
    position: 'Lönekonsult',
    imgUrl: 'mikayla_nguyen',
    email: 'mikayla@mconab.se',
    phone: '073 387 57 86',
  },
  {
    name: 'Jennifer Lundberg',
    position: 'Redovisningskonsult',
    imgUrl: 'jennifer_lundberg',
    email: 'jennifer@mconab.se',
    phone: '076 340 92 36',
  },
  {
    name: 'Patricia Bromar',
    position: 'Redovisningskonsult',
    imgUrl: 'patricia_bromar',
    email: 'patricia@mconab.se',
    phone: '073 324 75 28',
  },
  {
    name: 'Emelie Järpner',
    position: 'Lönekonsult',
    imgUrl: 'placeholder_women',
    email: 'emelie@mconab.se',
    phone: '070 924 70 78',
  },
  {
    name: 'Andrea Heggemann',
    position: 'Redovisningskonsult',
    imgUrl: 'placeholder_women',
    email: 'andrea@mconab.se',
    phone: '073 228 33 33',
  },
  {
    name: 'Anna Lund',
    position: 'Redovisningskonsult',
    imgUrl: 'placeholder_women',
    email: 'anna@mconab.se',
    phone: '072 397 68 13',
  },
  {
    name: 'Nerma Karabasic',
    position: 'Redovisningskonsult',
    imgUrl: 'placeholder_women',
    email: 'nerma@mconab.se',
    phone: '070 924 72 65',
  },
  {
    name: 'Amanda Andreasson',
    position: 'Redovisningskonsult',
    imgUrl: 'placeholder_women',
    email: 'amanda@mconab.se',
    phone: '070 777 47 02',
  },
  {
    name: 'Charlie',
    position: 'PR',
    imgUrl: 'charlie_fredholm',
    email: null,
    phone: null,
  },
  {
    name: 'Lars Fredholm',
    position: 'Kontorschef',
    imgUrl: 'lars_fredholm',
    email: 'lars@mconab.se',
    phone: '073 059 45 58',
  },
];

export default employees;
