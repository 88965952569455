// @flow
import styled from 'styled-components';
import { media } from '../../utils/style-utils';
import { hex2rgba } from '../../utils/general';
import Img from 'gatsby-image';

// main header of a page
export const MainHeader = styled.h1`
  font-family: Playfair Display, serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 1px;
  opacity: 0.95;
  margin-bottom: 2rem;
  color: ${({ isIE }) => (isIE ? '#000' : 'transparent')};
  text-shadow: ${({ colorFrom }) => `0px 2px 0px ${hex2rgba('#ffffff', 0.07)}`};
  background: ${props =>
    props.isIE
      ? 'transparent'
      : `linear-gradient(to right, ${props.colorFrom}, ${props.colorTo} 40%)`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${media.phone`
    font-size: 36px;
    letter-spacing: 1.2px;
  `};

  ${media.tablet`
      font-size: 48px;
    `};

  ${media.desktop`
      font-size: 56px;
    `};

  ${media.hd`
      font-size: 64px;
    `};
`;

// Sub header of a page (inverted colors)
export const SubHeader = styled.h3`
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1.2px;
  margin: 32px 0 24px 0;
  color: ${({ isIE }) => (isIE ? '#000' : 'transparent')};
  text-shadow: ${({ colorFrom }) => `0px 2px 0px ${hex2rgba('#ffffff', 0.07)}`};
  background: ${props =>
    props.isIE
      ? 'transparent'
      : `linear-gradient(to right, ${props.colorFrom}, ${props.colorTo})`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${media.tablet`
      font-size: 24px;
      margin: 32px 0 14px 0;
    `};

  ${media.hd`
      font-size: 32px;
      margin: 50px 0 18px 0;
    `};
`;

export const SectionHeader = styled(SubHeader.withComponent('h2'))`
  font-size: 32px;
  line-height: 1.2;

  ${media.tablet`
      font-size: 42px;
    `};

  ${media.hd`
      font-size: 56px;
    `};
`;

export const MainParagraph = styled.p`
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
  line-height: 1.4;

  ${media.tablet`
      font-size: 18px;
      margin-top: 24px;
    `};

  ${media.hd`
      font-size:20px;
      margin-top: 24px;
      line-height: 1.6;
    `};
`;

export const Root = styled.div`
  position: relative;
  background-color: #000;
`;

export const FlexDiv = styled.div(
  ({
    column,
    wrap = false,
    alignItems = 'stretch',
    justifyContent = 'flex-start',
  }) => ({
    display: 'flex',
    flexDirection: column && 'column',
    flexWrap: wrap ? 'wrap' : 'nowrap',
    alignItems,
    justifyContent,
  })
);

export const BgImg = styled.div`
    background: ${props =>
      `linear-gradient(to left, rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(/static/${
        props.src
      })`};
    background-size: cover;
    background-position: ${props => props.x} 20%;
    background-repeat: no-repeat;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: ${props => props.zIndex};
    opacity: ${props => (props.isVisible ? '1' : '0')}
    transition: opacity .6s ease-in-out;
  `;

export const P = styled.p`
  color: ${({ color = '#fff' }) => color};
  margin: ${({ margin }) => (margin ? margin : '0.6rem 0')};
  font-size: 1rem;
  font-weight: ${({ light }) => (light ? 300 : 400)};
  line-height: 1.4;

  ${media.hd`
    line-height: 1.6;
  `};
`;

export const GatsbyImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & img {
    object-position: center center !important;
    object-fit: ${({ contain }) => (contain ? 'contain' : 'cover')} !important;
  }

  ${media.tablet`
    & img {
      object-position: ${({ position }) =>
        position ? position : 'right center'} !important;
      `};
    }
`;

export const GatsbyImageBreaker = styled(GatsbyImage)`
  & img {
    object-position: 20% center !important;
    object-fit: ${({ contain }) => (contain ? 'contain' : 'cover')} !important;
  }

  ${media.tablet`
    & img {
      object-position: ${({ position }) =>
        position ? position : 'right center'} !important;
      `};
    }
`;

export const ExternalLink = styled.a`
  color: #000;
  font-weight: 500;
  cursor: pointer !important;
  text-decoration: underline;

  &:hover {
    color: #1061bf;
    transition: color 0.3 ease-in-out;
  }
`;
