// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trail, config, animated } from 'react-spring';
import Observer from 'react-intersection-observer';
import { graphql } from 'gatsby';

import { FadeUp } from '../Animations';
import Quote from '../Quote';
import Testimonial from '../Testimonial';
import Footer from '../Footer';

import {
  ReferencesPage,
  Section,
  MiddleSection,
  ContentWrapper,
  ContentImg,
  ContentImgBg,
  Content,
  RelativeParent,
  TestimonialSection,
  FlexChild,
  HeaderWrapper,
} from './styles';

import {
  MainHeader,
  MainParagraph,
  SectionHeader,
  GatsbyImage,
} from '../Styled';

type State = {
  isIE: boolean,
};

type Props = {
  data: Object,
};

export default class References extends React.Component<Props, State> {
  state = {
    isIE: false,
  };

  getSection(section: 'one' | 'two', isIE: boolean) {
    if (section === 'one') {
      return [
        <Testimonial
          tId="t0"
          name="Lind Models"
          services="Redovisning, rådgivning"
          logoUrl="./references/testimonials/lindmodels.svg"
          text="Här hjälper vi till med allt, utom fakturering som kunden sköter själv direkt i Fortnox. Allt material hanteras helt digitalt och antingen fotas eller scannas direkt till bokföringen."
          theme="dark"
          isIE={isIE}
        />,
        // <Quote
        //   body="Lorem ipsum client testimonial with some great content"
        //   author="Annika Lind"
        //   company="Lind Models"
        //   theme="dark"
        // />,
        // <Testimonial
        //   tId="t1"
        //   name="Tangiamo"
        //   services="Bokslut, kvartalsrapporter"
        //   logoUrl="./references/testimonials/tangiamo.png"
        //   text="Vi hjälper Tangiamo med månadsbokslut, kvartalsbokslut, årsbokslut samt upprättande av kvartalsrapport då bolaget är noterat."
        //   fullRow={true}
        //   theme="dark"
        //   isIE={isIE}
        // />,
      ];
    }
    return [];
    // return [
    //   <Testimonial
    //     tId="t2"
    //     name="Fredag"
    //     services="Fakturering, löner, bokslut"
    //     logoUrl="./references/testimonials/fredag.png"
    //     text="Vi sköter allt som har med ekonomi att göra, bokföring, betalningar, löner, bokslut, årsredovisning och bolagsdeklaration"
    //     isIE={isIE}
    //   />,
    //   // <Quote
    //   //   body="“Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci..”"
    //   //   author="Angelica Hart Lindh"
    //   //   company="corduroy studio"
    //   // />,
    //   <Testimonial
    //     tId="t3"
    //     name="Invid"
    //     services="Bolagsdeklaration"
    //     logoUrl="./references/testimonials/invid.svg"
    //     text="Genom löpande direktkontakt kompletterar vi årligen Invids egna ekonomifunktion med upprättning av årsbokslut, årsredovisning och bolagsdeklaration."
    //     fullRow={true}
    //     isIE={isIE}
    //   />,
    // ];
  }

  componentDidMount() {
    this.setState({
      isIE: /*@cc_on!@*/ false || !!document.documentMode,
    });
  }

  render() {
    const { isIE } = this.state;
    const { referencesImg, middleImg } = this.props.data;

    return (
      <ReferencesPage>
        <Helmet>
          <title>Mcon | Referenser</title>
        </Helmet>
        <Section bg="#fff" height={110}>
          <GatsbyImage
            fluid={referencesImg.childImageSharp.fluid}
            alt="Referenser huvudbild"
            backgroundColor="#20331d"
          />
          <Observer triggerOnce>
            {({ inView, ref }) => (
              <ContentWrapper ref={ref}>
                {inView && (
                  <RelativeParent>
                    <ContentImgBg />
                    <ContentImg />
                    <Content>
                      <FadeUp>
                        <MainHeader
                          colorFrom="#061405"
                          colorTo="#14300F"
                          isIE={isIE}
                        >
                          Mer än bara redovisning
                        </MainHeader>
                      </FadeUp>
                      <FadeUp delay={400}>
                        <MainParagraph>
                          Vi jobbar med små och medelstora företag från en mängd
                          olika industrier. Därför erbjuder vi en{' '}
                          <strong>personlig och kvalitativ service</strong>,
                          utformad efter våra{' '}
                          <strong>kunders specifika behov.</strong>
                        </MainParagraph>
                      </FadeUp>
                    </Content>
                  </RelativeParent>
                )}
              </ContentWrapper>
            )}
          </Observer>
        </Section>
        <Section bg="#fff" height={125}>
          <Observer triggerOnce>
            {({ inView, ref }) => {
              const section = this.getSection('one', isIE);
              return (
                <TestimonialSection ref={ref}>
                  {inView && (
                    <React.Fragment>
                      <HeaderWrapper>
                        <FadeUp delay={100}>
                          <SectionHeader
                            colorFrom="#202919"
                            colorTo="#56804A"
                            isIE={isIE}
                          >
                            Kunder vi jobbar med
                          </SectionHeader>
                        </FadeUp>
                      </HeaderWrapper>
                      <Trail
                        items={section}
                        keys={section.map((_, i) => i)}
                        from={{
                          opacity: 0,
                          x: -100,
                        }}
                        to={{ opacity: 1, x: 0 }}
                        config={config.molasses}
                        delay={400}
                        native
                      >
                        {item => ({ opacity, x }) => (
                          <FlexChild>
                            <animated.div
                              style={{
                                opacity,
                                transform: x.interpolate(
                                  x => `translate3d(${x}px, 0, 0)`
                                ),
                              }}
                            >
                              {item}
                            </animated.div>
                          </FlexChild>
                        )}
                      </Trail>
                    </React.Fragment>
                  )}
                </TestimonialSection>
              );
            }}
          </Observer>
        </Section>
        {/* <MiddleSection bg="#fff">
          <GatsbyImage
            fluid={middleImg.childImageSharp.fluid}
            alt="Våra kunder"
            position="center 20%"
          />
        </MiddleSection> */}
        {/* <Section bg="linear-gradient(to right, #0F270F, #68985A)" height={125}>
          <Observer triggerOnce>
            {({ inView, ref }) => {
              const section = this.getSection('two', isIE);
              return (
                <TestimonialSection ref={ref}>
                  {inView && (
                    <Trail
                      items={section}
                      keys={section.map((_, i) => i)}
                      from={{
                        opacity: 0,
                        x: -100,
                      }}
                      to={{ opacity: 1, x: 0 }}
                      config={config.molasses}
                      native
                    >
                      {item => ({ opacity, x }) => (
                        <FlexChild>
                          <animated.div
                            style={{
                              opacity,
                              transform: x.interpolate(
                                x => `translate3d(${x}px, 0, 0)`
                              ),
                            }}
                          >
                            {item}
                          </animated.div>
                        </FlexChild>
                      )}
                    </Trail>
                  )}
                </TestimonialSection>
              );
            }}
          </Observer>
        </Section> */}
        <Footer
          color="#fff"
          backgroundColor={`linear-gradient(to right, #0F270F, #68985A)`}
        />
      </ReferencesPage>
    );
  }
}

export const referencesQuery = graphql`
  query {
    referencesImg: file(relativePath: { eq: "references/referencesImg.jpg" }) {
      ...fluidImage
    }
    middleImg: file(relativePath: { eq: "references/middleImg.jpg" }) {
      ...fluidImage
    }
  }
`;
