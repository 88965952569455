import styled from 'styled-components';
import { withPrefix } from 'gatsby';

import { media } from '../../utils/style-utils';

export const ServicesPage = styled.article`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  justify-content: ${({ justify = 'flex-start' }) => justify}
  align-items: ${({ align = 'center' }) => align};
  background: ${({ bg }) => bg};
  background-repeat: no-repeat;
  width: 100%;
  height: 1200px;

  &:nth-child(2) {
    height: 1600px;
  }

  &:nth-child(4) {
    height: 900px;
  }

  &:first-child {
    height: 580px;
  }
  

  ${media.phone`
    &:first-child {
      height: 670px;
    }
  `};

  ${media.tablet`
    height: 1100px;

    &:first-child {
      height: 110vh;
    }

    &:nth-child(4) {
      height: 1300px;
    }

    &:nth-child(2) {
      height: 1300px;
    }

    &:last-of-type {
      height: 1300px;
    }
  `};

  ${media.desktop`
    height: 1000px;
    flex-direction: row;
    flex-wrap: wrap;

    &:first-child {
      height: 110vh;
    }

    &:nth-child(2) {
      height: 1200px;
    }

    &:nth-child(3) {
      height: 1100px;
    }
  `};

  ${media.hd`
    &:nth-child(4) {
      height: 1200px;
    }
  `};
`;

export const ContentWrapper = styled.div`
  position: absolute;
  width: 525px;
  height: 467px;
  z-index: 3;

  &#intro {
    left: -70px;
    bottom: -10px;
  }

  &#s1 {
    left: -10%;
  }

  &#s2 {
    left: -10px;
    bottom: 64px;
  }

  &#s5 {
    left: -10%;
    bottom: 0;
  }

  &#s3 {
    width: 700px;
    height: 622px;
    bottom: -5%;
    right: -180px;
  }

  ${media.phone`
    &#intro {
      left: -60px;
      bottom: -25px;
    }

    &#s3 {
      width: 700px;
      height: 622px;
      right: -110px;
      bottom: -5%;
    }
  `};

  ${media.tablet`
    width: 625px;
    height: 555px;

    &#intro {
      left: -35px;
      bottom: 40px;
    }

    &#s1{
      width: 500px;
      height: 444px;
      left: 200px;
      bottom: 110px;
    }

    &#s2 {
      left: 200px;
      bottom: -25px;
    }

    &#s5 {
      left: 142px;
      bottom: 48px;
    }

    &#s4 { 
      width: 600px;
      height: 600px;
      right: 24px;
      bottom: 48px;
    }

    &#s3 {
      right: -10px;
      bottom: 4%;
    }
  `};

  ${media.desktop`
    width: 700px;
    height: 622px;

    &#intro {
      width: 700px;
      height: 622px;
      left: 0;
      bottom: 80px;
    }

    &#s1 {
      width: 700px;
      height: 622px;
      left: 0px;
      bottom: 250px;
    }

    &#s2, &#s5 {
      width: 100%;
      left: 50px;
      top: 200px;
    }

    &#s3 {
      width: 800px;
      height: 711px;
      right: 10%;
      bottom: 76px;
    }

    &#s4 {
      width: 700px;
      height: 622px;
      left: -10px;
      bottom: 200px;
    }
  `};

  ${media.hd`
    width: 800px;
    height: 711px;

    &#intro {
      width: 800px;
      height: 740px;
      left: 3%;
      bottom: 80px;
    }

    &#s1 {
      width: 800px;
      height: 711px;
      left: -50px;
      bottom: 200px;
    }

    &#s3 {
      width: 800px;
      height: 711px;
      right: 35%;
      bottom: 76px;
    }

    &#s4 {
      width: 800px;
      height: 711px;
      left: -100px;
      bottom: 100px;
    }
  `};
`;

export const ContentImgBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ url }) => `url(${withPrefix(`./services/${url}`)})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 100%;
  ${'' /* z-index: 1; */};
`;

export const ContentImg = styled(ContentImgBg)`
  background-repeat: no-repeat;
  background-size: contain;
  width: 95%;
  height: 95%;
  margin: auto;
  z-index: 2;
`;

export const Content = styled.div`
  position: absolute;
  top: 90px;
  left: 100px;
  z-index: 3;
  max-width: 85vw;

  ${media.phone`
    top: 100px;
    left: 90px;
    max-width: 325px;
  `};

  ${media.tablet`
    top: 140px;
    left: 90px;
    max-width: 425px;
  `};

  ${media.desktop`
    top: 150px;
    left: 130px;
    max-width: 452px;
  `};

  ${media.hd`
    top: 180px;
    left: 150px;
    max-width: 520px;
  `};
`;

export const ServiceContent = styled(Content)`
  top: 60px;
  left: 240px;

  &#c3 {
    top: 100px;
    left: 250px;
  }

  ${media.tablet`
    left: 200px;

    &#c3 {
      left: 200px;
    }
  `};

  ${media.desktop`
    top: 110px;
    left: 200px;

    &#c3 {
      top: 132px;
      left: 216px;
    }
  `};

  ${media.hd`
    &#c3 {
      top: 112px;
      left: 216px;
    }
  `};
`;

export const RelativeParent = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
`;

export const FlexChild = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${media.desktop`
    width: 50%;
  `};
`;

export const Service = styled(FlexChild)`
  position: relative;
  padding: 0 16px;

  &#s1 {
    padding: 32px 16px 0;
  }

  &#s4 {
    padding: 148px 16px 0;
  }

  ${media.phone`
    &#s1 {
      padding: 64px 16px 0;
    }
  `};

  ${media.tablet`
    width: 75%;
    margin: 0 auto 0 32px;

    &#s1 {
      width: 85%;
      padding: 112px 16px 0 32px;
    }

    &#s4 {
      padding: 36px 16px 0 32px;
    }
  `};

  ${media.desktop`
    width: 50%;
    max-width: 500px;
    margin: auto;

    &#s1, &#s2, &#s4 {
      padding: 146px 32px 0 32px;
    }

    &#s5 {
      padding: 164px 16px 0 64px;
    }
  `};

  ${media.hd`
    max-width: 600px;
    margin-top: 80px;
  `};
`;

export const H3 = styled.h3`
  font-size: 1.26rem;
  font-weight: 500;
  color: ${({ color = '#fff' }) => color};
`;

export const Feature = styled.div`
  position: relative;
  margin-top: 3rem;
  padding: 0 1.2rem;
  border-left: ${({ borderColor = '#401D32' }) => `5px solid ${borderColor}`};
`;

export const From = styled.span`
  font-weight: 300;
  margin-right: 0.6rem;
`;

export const Price = styled.span`
  font-family: 'Playfair', serif;
  font-weight: 800;
  color: ${({ color = '#ecc6e0' }) => color};
  font-size: 1.6rem;
`;
